import { COLORS } from 'lib/theme';
import { SCREEN } from 'lib/constants';
import bamboo from 'assets/images/bamboo.png';
import styled from 'styled-components';

export default styled.div`
  background-color: ${COLORS.dark};
  background-image: url(${bamboo});
  background-repeat: no-repeat;
  background-size: contain;
  height: ${SCREEN.height}px;
  width: ${SCREEN.width}px;
  overflow: hidden;
  position: relative;
`;
