export const COLORS = {
  accent: '#2B5647',
  dark: '#2F4F4F',
  gray: '#3c4b64',
  light: '#F6F7FB',
  primary: '#5FC99F',
  white: '#FFFFFF',
  transparentWhite: 'rgba(255, 255, 255, 0.95)',
  transparentWhite30: 'rgba(255, 255, 255, 0.3)',
  transparentWhite50: 'rgba(255, 255, 255, 0.55)',
};
