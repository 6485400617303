export const sensors = {
  3845: { id: 3845, x: 195, y: 590, r: 22, area: 20, name: '정문', index: 2 },
  3874: {
    id: 3874,
    x: 210,
    y: 368,
    r: 27,
    area: 30,
    name: '백진쉼터',
    index: 1,
  },
  3846: { id: 3846, x: 223, y: 137, r: 45, area: 100, name: '후문', index: 0 },
  // 3695: { id: 3695, x: 425, y: 75, r: 35, area: 20, name: 'Test' },
};
