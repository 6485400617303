/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';

import { COLORS } from 'lib/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SCREEN } from 'lib/constants';
import chroma from 'chroma-js';
import { getRandomPosNegNumber } from 'lib/utils';
import heatmapJs from 'heatmap.js';
import mapSvg from 'assets/images/map.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  height: ${SCREEN.height}px;
  width: 450px;
  position: absolute;
  right: 0;
`;

const Heatmap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  & div[class^='heatmap-'] {
    position: absolute !important;
    height: 100%;
    width: 500px;
  }
`;

const MapContainer = styled.div`
  position: absolute;

  & div.img {
    height: 200px;
    width: 100%;
  }

  & img {
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    height: 85vh;
    width: 420px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    object-fit: cover;
  }
`;

const Sensors = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  & svg.sensors {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }

  & #icon {
    & path {
      fill: inherit;
    }
  }
`;

export default function SensorsMap({ data, sensors }) {
  const heatmapRef = useRef();

  const [dots, setDots] = useState([]);

  useEffect(() => {
    heatmapRef.current = sensors.map((sensor) =>
      heatmapJs.create({
        container: document.querySelector(`.heatmap-${sensor.id}`),
        gradient: {
          0.2: '#1E90FF',
          0.4: '#7FFFFF',
          0.6: '#56C133',
          0.8: '#FFCE55',
          1.0: '#DB4455',
        },
        blur: 0.75,
        maxOpacity: 0.75,
        minOpacity: 0.05,
        radius: sensor.r,
      })
    );
  }, []);

  useEffect(() => {
    if (heatmapRef?.current && data) {
      heatmapRef.current.forEach((heatmap, i) => {
        const points = [];
        const { x, y } = sensors[i];
        const { count, max } = data[i];
        points.push({ x, y, value: count });
        heatmap.setData({
          max: Math.floor(max),
          data: points,
        });
      });
    }
    if (data) {
      setDots(
        data.map((datum, i) => {
          const sensor = sensors[i];
          const circles = Array.from({ length: datum?.count }, (_, j) => ({
            id: `${sensor.id}-${j}`,
            x: sensor.x + getRandomPosNegNumber(sensor.r),
            y: sensor.y + getRandomPosNegNumber(sensor.r),
          }));
          return circles;
        })
      );
    }
  }, [data]);

  return (
    <Wrapper>
      <MapContainer>
        <img
          className="img"
          src={mapSvg}
          height={SCREEN.height}
          alt="KioskMap"
        />
        <Heatmap>
          {sensors.map((sensor) => (
            <div key={sensor.id} className={`heatmap-${sensor.id}`} />
          ))}
        </Heatmap>
        <Sensors>
          <svg className="sensors">
            <FontAwesomeIcon
              id="icon"
              icon="map-marker-alt"
              symbol="marker"
              color={COLORS.accent}
            />
            {data.map((datum, i) => {
              const sensor = sensors[i];
              const { level } = datum;
              return (
                <g key={sensor.id}>
                  <circle
                    cx={sensor.x}
                    cy={sensor.y}
                    r={sensor.r}
                    fill={level.fill}
                    fillOpacity="0.25"
                  />
                  {dots?.[i]?.map((dot) => (
                    <circle
                      key={dot.id}
                      cx={dot.x}
                      cy={dot.y}
                      r={2}
                      fill={COLORS.dark}
                      fillOpacity="0.5"
                    />
                  ))}
                  <use
                    x={sensor.x - 12.5}
                    y={sensor.y - 12.5}
                    href="#marker"
                    width={24}
                    height={24}
                    fill={chroma(level.fill).darken(0.25).css()}
                    stroke={chroma(level.fill).darken(5.0).css()}
                    strokeWidth={4}
                  />
                  <text
                    x={sensor.x}
                    y={sensor.y}
                    dy={-18}
                    textAnchor="middle"
                    fill={COLORS.black}
                    style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {sensor.name}
                  </text>
                </g>
              );
            })}
          </svg>
        </Sensors>
      </MapContainer>
    </Wrapper>
  );
}
