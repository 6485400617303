import React, { useEffect, useMemo, useState } from 'react';

import { COLORS } from 'lib/theme';
import { CONGESTION_LEVELS } from 'lib/constants';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchOpenWeatherApi } from 'lib/api';
import styled from 'styled-components';
import { useQuery } from 'react-query';

const InformationBoardComponent = styled.div`
  position: absolute;
  width: 50%;
  height: 59%;
  margin: 30px 20px 10px 40px;
  color: ${COLORS.light};

  & div.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: 100%;

    & div.title {
      color: ${COLORS.light};
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  & div.weather {
    align-items: center;
    background-color: ${COLORS.transparentWhite50};
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    color: ${COLORS.light};
    display: grid;
    font-size: 35px;
    height: 97%;
    width: 100%;
    margin-left: 1.5rem;
    text-align: center;

    & div.icon {
      padding-top: 1.5rem;
    }
    & div.temp {
      color: ${COLORS.light};
      font-size: 45px;
      font-weight: 600;
      text-align: center;
    }
    & div.date {
      font-size: 28px;
    }
    & div.time {
      font-size: 28px;
      padding-bottom: 2rem;
    }
  }

  & div.legends {
    background-color: ${COLORS.transparentWhite50};
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    font-size: 24px;
    line-height: 2.5;
    margin-bottom: 15px;
    padding: 25px;
    position: absolute;
    bottom: 0;
    right: 50%;
    left: 0;
  }

  & i.owi {
    color: ${COLORS.white};
  }
`;

export default function InformationBoard() {
  const [weatherInfo, setWeatherInfo] = useState({});
  const [time, setTime] = useState(
    DateTime.now().setLocale('ko').toFormat('cccc, t')
  );

  const legends = useMemo(
    () => [
      CONGESTION_LEVELS.sparse,
      CONGESTION_LEVELS.normal,
      CONGESTION_LEVELS.crowded,
      CONGESTION_LEVELS.congested,
    ],
    []
  );

  const weatherQuery = useQuery({
    queryKey: 'openWeather',
    queryFn: fetchOpenWeatherApi,
    refetchInterval: 60 * 1000, // refetch every min
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const interval = setInterval(
      () => setTime(DateTime.now().setLocale('ko').toFormat('cccc, t')),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (weatherQuery.data) {
      const { data } = weatherQuery;
      const {
        main: { temp },
        weather: [weather],
      } = data;
      setWeatherInfo({
        description: weather?.description,
        icon: weather?.icon,
        temperature: temp,
        date: DateTime.now().setLocale('ko').toFormat('dd/LLL yyyy'), // 14th Apr 2022
      });
    }
  }, [weatherQuery.data]);

  return (
    <InformationBoardComponent>
      <div className="container">
        <div>
          <div className="title">
            {process.env.REACT_APP_SITE_NAME}
            <hr />
          </div>
          <div className="legends">
            {legends.map((legend) => (
              <div key={legend.name}>
                <FontAwesomeIcon icon="circle" color={legend.fill} size="lg" />
                <span style={{ marginLeft: '0.6rem' }}>{legend.label}</span>
              </div>
            ))}
          </div>
        </div>

        {weatherQuery.isSuccess && (
          <div className="weather">
            <div className="icon">
              <i className={`owi owi-fw owi-4x owi-${weatherInfo.icon}`} />
            </div>
            <div className="temp">{Math.round(weatherInfo.temperature)}°c</div>
            <div className="date">{weatherInfo.date}</div>
            <div className="time">{time}</div>
          </div>
        )}
      </div>
    </InformationBoardComponent>
  );
}
